<mat-form-field class="example-full-width" appearance="outline" [ngClass]="this.withTopMargin ? 'secondRowSearchItems' : ''">
    <mat-label>{{labelName}}</mat-label>
    <input #stateInput (keyup)="0" matInput [placeholder]="labelName" aria-label="State" [matAutocomplete]="auto" [formControl]="itemCtrl" (change)="inputChange()">
    <mat-autocomplete #auto="matAutocomplete" #selectedItemValue>
        <mat-option (onSelectionChange)="stateInput.value !=undefined && onEnter($event)" *ngFor="let item of filteredItems | async" [value]="item.name">
        <span>{{ item.name }}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="!isItemSelected()">Selection required</mat-hint>
</mat-form-field>

