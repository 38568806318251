<mat-drawer-container class="edit-customer-container" autosize hasBackdrop="true" disableClose="true" position="end">
  <mat-drawer #drawer class="customer-sidenav" mode="over">    
    <change-customer></change-customer>
  </mat-drawer>  
<mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel class="searchPanel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          search
        </mat-panel-title>
        <mat-panel-description>
          enter search criteria
          <mat-icon>search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>  

      <table class="full-width" cellspacing="0">
      <td>
        <mat-form-field appearance="outline" >
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="searchData.name">
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline" >
          <mat-label>Address</mat-label>
          <input matInput [(ngModel)]="searchData.address">
        </mat-form-field>
      </td>
      <td>
        <number-results></number-results>
      </td>
    </table>
      <div class="searchButton">
        <button mat-raised-button (click)="clearSearchFields();" class="leftActionButton">clear</button>             
        <button mat-raised-button (click)="search()" class="actionButton">search</button>             
      </div>
    </mat-expansion-panel>        
  </mat-accordion>

<div class="mat-elevation-z8 tableContent">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">     
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
            Id
            </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            Name
            </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer">
            City
            </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
        </ng-container>
        
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by isFinished">
            Address
            </th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
        </ng-container>

        <ng-container matColumnDef="houseNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date finished">
            Nr
            </th>
            <td mat-cell *matCellDef="let element"> {{element.houseNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="zip">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by isFinished">
          Zip
          </th>
          <td mat-cell *matCellDef="let element"> {{element.zip}} </td>
      </ng-container>

      <ng-container matColumnDef="invoiceTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by isFinished">
        Invoice To
        </th>
        <td mat-cell *matCellDef="let element"> {{element.invoiceTo}} </td>
    </ng-container>
        
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>              
                <mat-icon (click)="addNew()" aria-label="Example icon-button with a heart icon" ngbTooltip="create customer" class="actionIcon">add</mat-icon>              
              </th>      
            <td mat-cell *matCellDef="let element; let i=index;">
                <mat-icon (click)="edit(element)" aria-label="Edit" class="actionIcon" ngbTooltip="edit customer">edit</mat-icon>
                <mat-icon (click)="delete(element['id'])" aria-label="Delete" ngbTooltip="delete customer" class="actionIcon" color="warn">delete</mat-icon>
              </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
        <mat-progress-spinner    
            mode="indeterminate">
        </mat-progress-spinner>
    </div>
    <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>
</mat-drawer-container>