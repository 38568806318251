import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'total-app';

  isLoggedIn!:boolean;

  checkLoggedInUser(){
    
    this.isLoggedIn = this.authService.isLoggedIn();
    //('app.component: checkLoggedInUser. User logged in = ' + this.isLoggedIn);

    //this.roles=this.authService.getUserRole();
  }

  constructor(private authService:AuthenticationService){
    //console.log('constr app.component')
  }
}


