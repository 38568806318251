import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatDrawer } from '@angular/material/sidenav';
import { InvoiceDto } from 'src/app/models/invoice';
import { SimpleObject } from 'src/app/models/simple-object';
import { InvoiceService } from 'src/app/services/invoice.service';
import { UploadInvoiceComponent } from 'src/app/common/controls/upload-invoice/upload-invoice.component';
import { ListsService } from 'src/app/services/lists.service';
import { CommonService } from 'src/app/services/common.service';
import { MatOption } from '@angular/material/core';

interface Options {
  id: number;
  name: string;
}

@Component({
  selector: 'change-invoice',
  templateUrl: './change-invoice.component.html',
  styleUrls: ['./change-invoice.component.css']
})
export class ChangeInvoiceComponent implements OnInit, AfterViewInit {
  constructor(private invoiceService: InvoiceService, private listsService: ListsService, public commonService: CommonService) {
    this.initLists();
  }

  matDrawer!: MatDrawer;

  customers!: SimpleObject[];

  terms: Options[] = [
    {id: 1, name: '14'},
    {id: 2, name: '31'},
    {id: 3, name: '42'}
  ];

  senders: Options[] = [
    {id: 1, name: 'LEGIT'},
    {id: 2, name: 'BINK-IT'}
  ];

  fiscalYears: Options[] = [
    {id: 1, name: '2013'},
    {id: 2, name: '2014'},
    {id: 3, name: '2015'},
    {id: 4, name: '2016'},
    {id: 5, name: '2017'},
    {id: 6, name: '2018'},
    {id: 7, name: '2019'},
    {id: 8, name: '2020'},
    {id: 9, name: '2021'},
    {id: 10, name: '2022'},
    {id: 11, name: '2023'},
    {id: 12, name: '2024'},
    {id: 13, name: '2025'},
    {id: 14, name: '2026'},
  ];

  percentages: Options[] = [
    {id: 0, name: ''},
    {id: 1, name: '6'},
    {id: 2, name: '18'},
    {id: 3, name: '21'}
  ];

selectedTerm = this.terms[1].id;
selectedSender = this.senders[0].id;
selectedSenderName = this.senders[0].name;
selectedFiscalYear = this.fiscalYears[10].id;
selectedVatPercentage = this.percentages[3].id;

invoice = {
  "id": 0,
  "invoiceNumber":'',
  "description":'',
  "invoiceDate":new Date(),
  "payedDate": undefined,
  "amount":'0',
  "tax":'0',
  "totalAmount":'0',
  "fullyPayed":false,
  "fiscalYear": Number(this.fiscalYears[10].name),
  "customerId":0,  
  "noHours": 0,
  "hourlyRate":'0',
  "filename":'',
  "taxRate": Number(this.percentages[3].name),
  "sender": this.senders[0].name,
  "term": this.terms[1].id
} as InvoiceDto;

isLoading = false;
allFieldsFilled = false;
oldInvoice = {};

@ViewChild('selectedCustomerId') matSelectCustomer!: MatSelect;
@ViewChild(UploadInvoiceComponent) uploadInvoiceComponent!: UploadInvoiceComponent;

ngOnInit(): void { }

ngAfterViewInit(): void {
  this.uploadInvoiceComponent.attachmentSaved.subscribe(message => {
    this.invoice.filename = message;
  });
}

initLists() {
  var dataCustomers = this.listsService.getCustomerList();

  if (!this.commonService.stringIsNullOrEmpty(dataCustomers))
  {
    var orgCustomerList = JSON.parse(dataCustomers || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
    this.customers = orgCustomerList as SimpleObject[];
  }
}

bind(invoice: InvoiceDto, drawer: MatDrawer) {

  this.isLoading = true;
  this.matDrawer = drawer;

  this.resetFields();
  
  if (invoice != null) {
    
    this.oldInvoice = JSON.parse(JSON.stringify(invoice)); //Save current values in case update is cancelled.

    this.uploadInvoiceComponent.setInvoiceId(invoice.id);
    this.invoice = invoice;

    this.setTwoNumberDecimal(); //TODO: check if necessary....

    if (invoice.taxRate !== null && invoice.taxRate !== undefined)
    {
       var idx = this.percentages.findIndex(t => t.name === invoice.taxRate.toString());
       this.selectedVatPercentage = this.percentages[idx].id;
    }
    else 
      this.selectedVatPercentage = this.percentages[0].id;

    if (invoice.hourlyRate === undefined || invoice.hourlyRate === null || invoice.hourlyRate === 'NaN')
      this.invoice.hourlyRate = '0';

    if (invoice.fiscalYear !== undefined && invoice.fiscalYear !== null)
    {
        var idx = this.fiscalYears.findIndex(f => f.name === invoice.fiscalYear.toString());
        this.selectedFiscalYear = this.fiscalYears[idx].id;
    }
    else
        this.selectedFiscalYear = this.fiscalYears[0].id;

    if (invoice.payedDate !== null && invoice.payedDate !== undefined)
      this.invoice.payedDate = new Date(invoice.payedDate); //json received from an API does not deliver correct TS dates.

    if (invoice.invoiceDate !== null && invoice.invoiceDate !== undefined)
      this.invoice.invoiceDate = new Date(invoice.invoiceDate); //json received from an API does not deliver correct TS dates.      

    if (invoice.sender === null || invoice.sender === undefined || invoice.sender === '')
      this.invoice.sender = this.senders[0].name;

    this.matSelectCustomer.value = invoice.customerId;
    this.allRequiredFieldsFilled();
    this.matDrawer.open();
    this.isLoading = false;

 }


}

new (drawer: MatDrawer) {
  this.matDrawer = drawer;
  this.invoice = {
    "id": 0,
    "invoiceNumber": '',
    "description": '',
    "invoiceDate": new Date(),
    "payedDate": undefined,
    "amount": '0',
    "tax": '0',
    "totalAmount": '0',
    "fullyPayed": false,
    "fiscalYear": Number(this.fiscalYears[10].name),
    "customerId": 0,   
    "noHours": 0,
    "hourlyRate":'0',
    "filename":'',
    "taxRate": Number(this.percentages[3].name),
    "sender": this.senders[0].name,
    "term": this.terms[1].id
  } as InvoiceDto;

  this.selectedTerm = this.terms[1].id;
  this.selectedSender = this.senders[0].id;
  this.selectedFiscalYear = this.fiscalYears[10].id;
  this.selectedVatPercentage = this.percentages[3].id;
  
  this.matSelectCustomer.options.forEach((data: MatOption) => data.deselect());

  this.allRequiredFieldsFilled();
  this.matDrawer.open();
}

save() {

  //Sending a date back that is changed on the client-side needs an adjustment.
  if (this.invoice.payedDate !== null && this.invoice.payedDate !== undefined)
  {
    let UTCDate1 = Date.UTC(this.invoice.payedDate.getFullYear(), this.invoice.payedDate.getMonth(), this.invoice.payedDate.getDate()) - this.invoice.payedDate.getTimezoneOffset();
    this.invoice.payedDate = new Date(UTCDate1);
  }

  if (this.invoice.invoiceDate !== null && this.invoice.payedDate !== undefined)
  {
    let UTCDate2 = Date.UTC(this.invoice.invoiceDate.getFullYear(), this.invoice.invoiceDate.getMonth(), this.invoice.invoiceDate.getDate()) - this.invoice.invoiceDate.getTimezoneOffset();
    this.invoice.invoiceDate = new Date(UTCDate2);
  }

  var retValue: any;
  var newInvoice = this.invoice.id === 0;

  retValue = newInvoice ? this.invoiceService.create(this.invoice) : this.invoiceService.update(this.invoice);
}

allRequiredFieldsFilled () {

  //Check for fields that were already calculated in older invoices. For these cases the original/base fields are 
  //missing
  var calculatedFieldsFilled = 
  this.invoice.totalAmount !== undefined && this.invoice.totalAmount !== null && this.invoice.totalAmount !== ''
  && this.invoice.amount !== undefined && this.invoice.amount !== null && this.invoice.amount !== ''
  && this.invoice.tax !== undefined && this.invoice.tax !== null && this.invoice.tax !== '';
  
  //Check for fields that normally need to be present in order to caculate the other amounts
  var originalFieldsFilled = 
  this.invoice.taxRate !== null && this.invoice.taxRate !== undefined && this.invoice.taxRate !== 0
  && this.invoice.noHours !== null && this.invoice.noHours !== undefined && this.invoice.noHours > 0
  && this.invoice.hourlyRate !== null && this.invoice.hourlyRate !== undefined && this.invoice.hourlyRate !== '';


  this.allFieldsFilled =
   this.invoice.description !== null && this.invoice.description !== undefined && this.invoice.description !== ''
   && this.invoice.invoiceDate !== undefined && this.invoice.invoiceDate !== null
   && (originalFieldsFilled || calculatedFieldsFilled);

   return this.allFieldsFilled;
 }

closeDrawer() {
  this.commonService.resetValues(this.invoice, this.oldInvoice); //For restoring values in the table-view
  this.matDrawer.close();
 }

 setTwoNumberDecimal() {
  this.invoice.hourlyRate = parseFloat(this.invoice.hourlyRate).toFixed(2);
  this.calculatePrices();
}

generateInvoice () {  
  this.invoiceService.generate(this.invoice).subscribe(response => {
    var filename = response.headers.get('x-file-name');
    let blob:Blob=response.body as Blob;
    let a = document.createElement('a');

    if (filename !== undefined && filename !== null)
    {
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    }
  });
}

calculatePrices() {
  if (!this.isLoading) {

    var rateOk = this.invoice.hourlyRate !== undefined && this.invoice.hourlyRate !== null && this.invoice.hourlyRate !== '0';
    var vatOk = this.invoice.taxRate !== null && this.invoice.taxRate !== 0;
    var hoursOk = this.invoice.noHours != undefined && this.invoice.noHours !== null && this.invoice.noHours !== 0;

    if (rateOk && vatOk && hoursOk)
    {
        var vatPercentage = this.invoice.taxRate;

        this.invoice.amount = parseFloat((this.invoice.noHours * Number(this.invoice.hourlyRate)).toString()).toFixed(2); // (this.noHours * Number(this.rate)).toString();
        this.invoice.tax =  parseFloat((((Number(this.invoice.amount))/100) * vatPercentage).toString()).toFixed(2); // (((Number(this.amount))/100) * vatPercentage).toString();
        this.invoice.totalAmount = parseFloat((Number(this.invoice.amount) + Number(this.invoice.tax)).toString()).toFixed(2); // (Number(this.amount) + Number(this.vat)).toString();
    }

    this.allRequiredFieldsFilled();
  }
}

selectedCustomerChanged() {
  this.invoice.customerId  = this.matSelectCustomer.value;
  this.allRequiredFieldsFilled();
}

selectedFiscalYearChanged(){
  var fiscalYear = this.fiscalYears.find(f => f.id === this.selectedFiscalYear)?.name;
  if (fiscalYear !== undefined)
    this.invoice.fiscalYear = Number(fiscalYear);
  this.allRequiredFieldsFilled();
}

selectedTermChanged() {
  var term = this.terms.find(f => f.id === this.selectedTerm)?.name;
  if (term !== undefined)
    this.invoice.term = Number(term);
  this.allRequiredFieldsFilled();
}

selectedSenderChanged() {
 var sender = this.senders.find(f => f.id === this.selectedSender)?.name;
 if (sender !== undefined && sender !== null)
  this.invoice.sender = sender;
  this.allRequiredFieldsFilled();
}

selectedVatPercentageChanged() {
  var percentage = this.percentages.find(f => f.id === this.selectedVatPercentage)?.name;
  if (percentage !== undefined && percentage !== '')
    this.invoice.taxRate = Number(percentage);
  this.calculatePrices();
  this.allRequiredFieldsFilled();
}

formatVatPercentage (perc:string) {
    if (perc === '')
        return '';
    else return perc + '%';
}

formatTerms (nOfdays:string) {
  if (nOfdays === '')
      return '0';
  else return nOfdays + ' days';
}

isCustomerSelected () : boolean {
  return this.invoice.customerId > 0;
}

resetFields () {    
   this.selectedTerm = this.terms[1].id;
   this.selectedSender = this.senders[0].id;
   this.selectedFiscalYear = this.fiscalYears[10].id;
   this.selectedVatPercentage = this.percentages[3].id;
   this.matSelectCustomer.value = 0;
}
}
