import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NumberResultsComponent } from 'src/app/common/controls/number-results/number-results.component';
import { Roles } from 'src/app/common/enums';
import { ImportDto, ImportSearch } from 'src/app/models/import';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BankImportService } from 'src/app/services/bank-import.service';
import { CommonService } from 'src/app/services/common.service';

const importArr: ImportDto[] = [];

@Component({
  selector: 'bank-import',
  templateUrl: './bank-import.component.html',
  styleUrls: ['./bank-import.component.css']
})

export class BankImportComponent  implements AfterViewInit, OnInit{
  displayedColumns: string[] = ['id','creationDate','fileName','creationUserName', 'actions'];
  dataSource = new MatTableDataSource(importArr);
  isLoading = false;
  searchData = { } as ImportSearch;
  importEnabled = false;
  validationEnabled = false;

  constructor(private service: BankImportService, private commonService: CommonService, private _liveAnnouncer: LiveAnnouncer, private authenticationService: AuthenticationService) { 
  }

  isPrivate = this.authenticationService.isPrivateBank();;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild(NumberResultsComponent) numberResultsComponent!: NumberResultsComponent;
  @ViewChild('fileInput') fileInput!: { nativeElement: { click: () => void; files: { [key: string]: File; }; }; };

  file: File | null = null;

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    this.accordion.openAll();
  }

  addDateFromEvent(event: MatDatepickerInputEvent<Date>) {
    if (event.value !== null)
    {
      var dt = new Date(event.value.toString());
      let UTCDate = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) - dt.getTimezoneOffset();
      this.searchData.dateFrom = new Date(UTCDate);    
    }   
   }
  
   addDateToEvent(event: MatDatepickerInputEvent<Date>) {
    if (event.value !== null)
    {
      var dt = new Date(event.value.toString());
      let UTCDate = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()) - dt.getTimezoneOffset();
      this.searchData.dateTo = new Date(UTCDate);    
    }
  }

  userHasRole() : boolean {
    return this.authenticationService.isUserInRole(Roles.privateBank);  
  }
    
  changeConnection (event: MatCheckboxChange)
  {
    this.authenticationService.setPrivateBank(this.isPrivate);
    console.log('Change connection to private: ' + event.checked);    
  }
  
  clearSearchFields() {
    this.searchData = {} as ImportSearch;
    this.dataSource = new MatTableDataSource([] as ImportDto[]);
  }
  
  search() {
    this.isLoading = true;

      this.searchData.results = this.numberResultsComponent.getSelectedValue();
      this.searchData.isPrivateAccount = this.isPrivate;
      this.service.search(this.searchData)
    .subscribe(response => {
            this.dataSource = new MatTableDataSource(response.body as ImportDto[]);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.accordion.closeAll();
            this.isLoading = false;
        }, error => {
            alert('searchImportss: An unexpected error occurred.');
            this.isLoading = false;
            console.log(error);
        });
  }  


  edit(bankImport: ImportDto) {
    //this.changeTransactionComponent.bind(transaction, this.drawer);
}

addNew () {

}

download(bankImport: ImportDto) {   
  this.service.download(bankImport.id).subscribe(response => {
    let blob:Blob=response.body as Blob;
    let a = document.createElement('a');

    if (bankImport.filename !== undefined)
    {
      a.download = bankImport.filename;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    }
  })
}


  formatDate(srcDate: Date) {
    return this.commonService.formatToNlDisplayDate(srcDate);
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
