import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.css']
})
export class UploadInvoiceComponent implements OnInit {
  progress!: number;
  message!: string;
  invoiceId = 0;

  @Output() public onUploadFinished = new EventEmitter();
  
  attachmentSaved: EventEmitter<string> = new EventEmitter<string>();

  constructor(private http: HttpClient, private invoiceService: InvoiceService) {  }
  
  ngOnInit() {  }

  uploadFile = (files:any) => {
    if (files.length === 0) {
      return;
    }
  
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    
    this.invoiceService.upload(this.invoiceId, formData).subscribe({
        next: (event) => {    
          this.attachmentSaved.emit(fileToUpload.name);
        },
        error: (err: HttpErrorResponse) => console.log(err)
    });
  }

  public setInvoiceId(invoiceId: number) {
      this.invoiceId = invoiceId;
  }
}