import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";;
import { LoginRequestModel } from '../models/loginRequestModel';
import { LoginResponseModel } from '../models/loginResponseModel';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { AuthenticationData, List, Roles } from '../common/enums'

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private baseUrl = environment.baseUrl+'/authorization';

  constructor(private router:Router, private http:HttpClient) { }

  isLoggedIn(){
    return !!this.getAccessToken() && !this.isTokenExpired()
  }

  isPrivateBank() : boolean {
    let storedValue = localStorage.getItem(AuthenticationData.isPrivateBank);

    if (storedValue !== null && storedValue !== undefined && storedValue !== '')
        return storedValue === 'true';
    else 
        return false;
  }

  setPrivateBank(isPrivateBank: boolean) {
    localStorage.setItem(AuthenticationData.isPrivateBank, isPrivateBank.toString());
  }

  addUsername(username:string){
    localStorage.setItem(AuthenticationData.username, username);
  }

  addUserId(userId: string) {
    localStorage.setItem(AuthenticationData.userId, userId);
  }

  addFullName(fullname:string){
    localStorage.setItem(AuthenticationData.fullname, fullname);
  }

  addAccessToken(accessToken:string){
    localStorage.setItem(AuthenticationData.accesstoken, accessToken);
  }

  addRefreshToken(refToken:string){
    localStorage.setItem(AuthenticationData.refreshtoken, refToken);
  }  

  getAccessToken(){
    return localStorage.getItem(AuthenticationData.accesstoken);
  }

  getUsername(){
    return localStorage.getItem(AuthenticationData.username);
  }

  getUserId(){
    return localStorage.getItem(AuthenticationData.userId);
  }

  getFullName(){
    return localStorage.getItem(AuthenticationData.fullname);
  }

  getRefreshToken(){
    return localStorage.getItem(AuthenticationData.refreshtoken);
  }    

  isTokenExpired(){
    const token: string=this.getAccessToken()??"";
        if(!token)
          return false;
        const tokenSplit:string=token.split('.')[1];
        const decodedString:string=atob(tokenSplit);
        const jsonString=JSON.parse(decodedString);
        const expiry = (jsonString).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
  
  fromBinary(encoded: string) {
    const binary: string = atob(encoded);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }

  login(model:LoginRequestModel){
  return this.http.post<LoginResponseModel>(this.baseUrl,model);
  }

  
  getUsers()
  {
    return this.http.get(this.baseUrl + '/getusers') .pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    }));
  }

  logout() {
     localStorage.removeItem(AuthenticationData.username);
     localStorage.removeItem(AuthenticationData.fullname);
     localStorage.removeItem(AuthenticationData.accesstoken);
     localStorage.removeItem(AuthenticationData.refreshtoken);
     localStorage.removeItem(AuthenticationData.userId);
     localStorage.removeItem(List.customers);
     localStorage.removeItem(List.tasks);
     localStorage.removeItem(List.projects);
     localStorage.removeItem(List.accounts);
     localStorage.removeItem(List.categories);

     this.router.navigate(['/login']).then(() => {
        window.location.reload();
     });
  }

  getUserRole() : string [] {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.getAccessToken()??"");
    if(decodedToken) {      
      let roles:string =  decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].toString(); //.toSpliced();
      if (roles !== null && roles !== null)
      {
        if (roles.indexOf(',') === -1) return [roles.toString()];
        else
          return roles.split(','); 
      }
    }

    return [];
  }

  isUserInRole (role: Roles) : boolean {
    let roles = this.getUserRole();
    let retValue = false;
    if (roles !== undefined && roles !== null) {
      roles.forEach(r => {
        if (r.toUpperCase() === role)
          retValue = true;
      });   
    }

    return retValue;

}

  // refreshing the access token
  // async refreshingToken():Promise<boolean>{
  //   const token = this.getAccessToken();
  //   const refreshToken = this.getRefreshToken();
  //   if(!token || !refreshToken){
  //      return false;
  //   }
  //   let success!:boolean;
  //   const data:RefreshTokenRequest={accessToken:token,refreshToken:refreshToken};

  //   this.tokenService.generateRefreshToken(data).subscribe({
  //      next: (response)=>{
  //         this.addAccessToken(response.accessToken);
  //         this.addRefreshToken(response.refreshToken);
  //      },
  //      error: (error)=>{
  //       console.log(error);
  //       success=false;
  //      }
  //   });
  //   return success;
  // }
}
