<mat-card style="width: 450px;">
    <mat-card-content>
    <table class="example-full-width" cellspacing="0">
        <th></th>
        <th></th>
        <th></th>
        <tr>
            <td colspan="3">
                <mat-form-field appearance="outline">
                    <mat-label>Customer</mat-label>
                    <mat-select #selectedCustomerId (valueChange)="selectedCustomerChanged()">
                      <mat-option *ngFor="let customer of customers" [value]="customer.id">
                        {{customer.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </td>           
        </tr>
            <tr>
            <td colspan="3">
                <mat-form-field appearance="outline" class="nextRowItems">
                    <mat-label>Project</mat-label>
                    <mat-select #selectedProjectId (valueChange)="selectedProjectChanged()">
                      <mat-option *ngFor="let project of projects" [value]="project.id">
                        {{project.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint *ngIf="!isProjectSelected()">Project required</mat-hint>
                  </mat-form-field>
            </td>
        </tr>
        <tr>
            <td colspan="3">
            <mat-form-field appearance="outline" class="nextRowItems">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name task" type="text" [(ngModel)]="task.name" (change)="allRequiredFieldsFilled()">
                <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(task.name)">Name required</mat-hint>
            </mat-form-field>
        </td>
        </tr>
        <tr>
            <td colspan="3"> 
            <mat-form-field appearance="outline" class="nextRowItems">
                <mat-label>Description</mat-label>
                <input matInput placeholder="Description" type="text" [(ngModel)]="task.description">
            </mat-form-field>
        </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems" style="max-width: 120px;">
                    <mat-label>Hours</mat-label>
                    <input matInput placeholder="Number (hours)" type="number" [(ngModel)]="task.noHours">
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems" style="max-width: 120px;">
                    <mat-label>Complete %</mat-label>
                    <input matInput placeholder="Number (hours)" type="number" [(ngModel)]="task.percentageCompleet">
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="nextRowItems" style="max-width: 165px;">
                    <mat-label>Date finished</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="task.dateFinished"> 
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
            </tr>           
    </table>   
    <div class="searchButton">
        <button mat-raised-button class="leftActionButton" (click)="closeDrawer()">cancel</button>             
        <button mat-raised-button class="actionButton" [disabled]="!allFieldsFilled" (click)="save()">save</button>             
    </div>
    </mat-card-content>
</mat-card>
