import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TransactionDto, TransactionSearch } from '../models/transaction';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TransactionService {

  constructor(private http: HttpClient) { }

  private baseUrl = environment.baseUrl + '/bank';

  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  transactionError: EventEmitter<string> = new EventEmitter<string>();

  search(searchData: TransactionSearch) { 
    let params = new HttpParams();
    params = params.append("accountId", searchData.accountId === null || searchData.accountId === undefined ? '' : searchData.accountId);
    params = params.append("categoryId", searchData.categoryId === null || searchData.categoryId === undefined ? '' : searchData.categoryId);
    params = params.append("description", searchData.description === null || searchData.description === undefined ? '' : searchData.description);
    params = params.append("dateFrom", searchData.dateFrom === null || searchData.dateFrom === undefined ? '' : searchData.dateFrom.toLocaleDateString('en-US'));
    params = params.append("dateTo", searchData.dateTo === null || searchData.dateTo === undefined ? '' : searchData.dateTo.toLocaleDateString('en-US'));
    params = params.append("referenceName", searchData.referenceName === null || searchData.referenceName === undefined ? '' : searchData.referenceName);
    params = params.append("referenceNumber", searchData.referenceNumber === null || searchData.referenceNumber === undefined ? '' : searchData.referenceNumber);
    params = params.append("minimumAmount", searchData.minimumAmount === null || searchData.minimumAmount === undefined ? '' : searchData.minimumAmount);
    params = params.append("maximumAmount", searchData.maximumAmount === null || searchData.maximumAmount === undefined ? '' : searchData.maximumAmount);
    params = params.append("isPrivateAccount", searchData.isPrivateAccount === null || searchData.isPrivateAccount === undefined ? '' : searchData.isPrivateAccount);
    params = params.append("results", searchData.results);

    return this.http.get(this.baseUrl, { observe: "response", params }).pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    }));
  }

  async getAccountList(isPrivate: boolean) {     
    const source$ = this.http.get(this.baseUrl + '/getaccountlist?isPrivate=' + isPrivate);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));        
    return firstResult;
  }

  async getCategoryList(isPrivate: boolean) {     
    const source$ = this.http.get(this.baseUrl + '/getcategorylist?isPrivate=' + isPrivate);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));        
    return firstResult;
  }

  async validateTransactions(isPrivate: boolean, formData: FormData, )
  {
    const source$ = this.http.post(this.baseUrl + '/ImportValidation?isPrivate=' + isPrivate, formData);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));        
    return firstResult;  
  }

  async importTransactions(isPrivate: boolean, formData: FormData, userId: number)
  {
    const source$ = this.http.post(this.baseUrl + '/Import?isPrivate=' + isPrivate + '&userId=' + userId, formData);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));        
    return firstResult;  
  }

  handleError(error: HttpErrorResponse) {
    debugger;
    this.transactionError.emit('Transaction-service error: ' + error.error);
  }
}
