import { Injectable } from '@angular/core';
import { TaskService } from './task.service';
import { ProjectService } from './project.service';
import { CustomerService } from './customer.service';
import { SimpleObject } from '../models/simple-object';
import { List } from '../common/enums'
import { BankImportService } from './bank-import.service';
import { TransactionService } from './transaction.service';

@Injectable({
  providedIn: 'root'
})

export class ListsService {

  constructor(private taskService: TaskService, private projectService: ProjectService, private customerService: CustomerService, private transactionService: TransactionService) { }

  addTaskList(taskList:string) {
    localStorage.setItem(List.tasks, taskList);
  }

  addProjectList(projectList:string) {
    localStorage.setItem(List.projects, projectList);
  }

  addCustomerList(customerList:string) {
    localStorage.setItem(List.customers, customerList);
  }

  addAccountList(accountList:string) {
    localStorage.setItem(List.accounts, accountList);
  }

  addCategoryList(categoyList:string) {
    localStorage.setItem(List.categories, categoyList);
  }

  getTaskList()
  {
    return localStorage.getItem(List.tasks);
  }

  getProjectList()
  {
    return localStorage.getItem(List.projects);
  }

  getCustomerList()
  {
    return localStorage.getItem(List.customers);
  }

  getAccountList()
  {
    return localStorage.getItem(List.accounts);
  }

  getCategoryList()
  {
    return localStorage.getItem(List.categories);
  }

  updateCustomerList(id: number, name: string)
  {
      var rawData = this.getCustomerList();
      
      if (rawData !== null)
        this.addCustomerList(this.changeItem(rawData, id, name));
  }

  deleteFromCustomerList(id: number)
  {
    var rawData = this.getCustomerList();
      
    if (rawData !== null)
       this.addCustomerList(this.deleteItem(rawData, id));
  }

  updateTaskList(id: number, name: string)
  {
    var rawData = this.getTaskList();
      
    if (rawData !== null)
       this.addTaskList(this.changeItem(rawData, id, name));
  }

  deleteFromTaskList(id: number)
  {
    var rawData = this.getTaskList();   

    if (rawData !== null)
       this.addTaskList(this.deleteItem(rawData, id));
  }

  updateProjectList(id: number, name: string)
  {
    var rawData = this.getProjectList();
      
    if (rawData !== null)
      this.addProjectList(this.changeItem(rawData, id, name));
  }

  private changeItem(list: string, id: number, name: string) : string
  {
      var objList = JSON.parse(list) as  SimpleObject[];
      var idx = objList.findIndex(l => l.id === id);

      if (idx !== undefined && idx !== null && idx !== -1) //update existing item
          objList[idx].name = name;
      else //create new entry
          objList.push({'id': id, 'name': name} as SimpleObject);

      return JSON.stringify(objList);
  }

  private deleteItem(list: string, id: number) : string
  {
      var objList = JSON.parse(list) as  SimpleObject[];
      var idx = objList.findIndex(l => l.id === id);
      
      if (idx !== undefined && idx !== null && idx !== -1)
          objList.splice(idx, 1);

      return JSON.stringify(objList);
  }

  getBankLists(privateBank: boolean)
  {
    this.transactionService.getCategoryList(privateBank).then((data) => {      
      this.addCategoryList(JSON.stringify(data));
    }); 

    this.transactionService.getAccountList(privateBank).then((data) => {      
      this.addAccountList(JSON.stringify(data));
    }); 
  }

  // refreshCategoryList(privateBank: boolean)
  // {
  //   this.transactionService.getCategoryList(privateBank).then((data) => {      
  //     this.addCategoryList(JSON.stringify(data));
  //   }); 
  // }

  // refreshAccountList(privateBank: boolean)
  // {
  //   this.transactionService.getAccountList(privateBank).then((data) => {      
  //     this.addAccountList(JSON.stringify(data));
  //   }); 
  // }

  fillLists(fillBankLists:boolean) {
    this.taskService.getList().then((data) => {      
      this.addTaskList(JSON.stringify(data));
    });

    this.projectService.getList().then((data) => {      
      this.addProjectList(JSON.stringify(data));
    });

    this.customerService.getList().then((data) => {      
      this.addCustomerList(JSON.stringify(data));
    }); 
    
    if (fillBankLists)
    {
      this.transactionService.getCategoryList(false).then((data) => {      
        this.addCategoryList(JSON.stringify(data));
      }); 

      this.transactionService.getAccountList(false).then((data) => {      
        this.addAccountList(JSON.stringify(data));
      }); 
    }    
  }
}
