import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { InvoiceUpdate, InvoiceDto, InvoiceSearch } from '../models/invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private baseUrl = environment.baseUrl + '/Invoice';
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  invoiceError: EventEmitter<string> = new EventEmitter<string>();
  invoiceChanged: EventEmitter<InvoiceDto> = new EventEmitter<InvoiceDto>();
  invoiceNew: EventEmitter<InvoiceDto> = new EventEmitter<InvoiceDto>();
  invoiceDeleted: EventEmitter<number> = new EventEmitter();

  constructor(private http: HttpClient) { }

  search(invoiceSearch: InvoiceSearch) {    
    
  let params = new HttpParams();
   params = params.append("invoiceNumber", invoiceSearch.invoiceNumber === null || invoiceSearch.invoiceNumber === undefined ? '' : invoiceSearch.invoiceNumber);
   params = params.append("description", invoiceSearch.description === null || invoiceSearch.description === undefined ? '' : invoiceSearch.description);
   params = params.append("invoiceDateFrom", invoiceSearch.invoiceDateFrom === null || invoiceSearch.invoiceDateFrom === undefined ? '' : invoiceSearch.invoiceDateFrom.toLocaleDateString('en-US'));
   params = params.append("invoiceDateTo", invoiceSearch.invoiceDateTo === null || invoiceSearch.invoiceDateTo === undefined ? '' : invoiceSearch.invoiceDateTo.toLocaleDateString('en-US'));
   params = params.append("customerId", invoiceSearch.customerId === null || invoiceSearch.customerId === undefined ? '' : invoiceSearch.customerId.toString());
   params = params.append("minimumAmount", invoiceSearch.minimumAmount === null || invoiceSearch.minimumAmount === undefined? '' : invoiceSearch.minimumAmount.toString());
   params = params.append("maximumAmount", invoiceSearch.maximumAmount === null || invoiceSearch.maximumAmount === undefined? '' : invoiceSearch.maximumAmount.toString());
   params = params.append("results", invoiceSearch.results);

   return this.http.get(this.baseUrl, { observe: "response", params }).pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    })); 
  }

  delete(id: string) {  
    return this.http.delete(this.baseUrl + '/' + Number(id), {headers: this.headers}).subscribe((response) => {      
      this.invoiceDeleted.emit(Number(response));
      console.log('Invoice deleted: ' + response);
    });
  }  

  async update(invoice: InvoiceDto) { 
    const body: InvoiceUpdate =  {
      "id":invoice.id,
      "invoiceNumber": invoice.invoiceNumber,
      "payedDate": invoice.payedDate,
      "fullyPayed": invoice.fullyPayed,
      "description": invoice.description,
      "invoiceDate": invoice.invoiceDate,
      "customerId": invoice.customerId,
      "noHours": invoice.noHours,
      "hourlyRate": invoice.hourlyRate.toString(), //ugly workaround for old invoices.
      "amount": invoice.amount.toString(), //ugly workaround for old invoices.
      "vatRate": invoice.taxRate,
      "fiscalYear": invoice.fiscalYear,
      "creationUserId": 0,
      "changeUserId": invoice.changeUserId,
      "term": invoice.term,
      "sender": invoice.sender,
      "filename": invoice.filename
    };

    const source$ = this.http.put(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));    
    this.invoiceChanged.emit(firstResult as InvoiceDto);   
  }
    
    handleError(error: HttpErrorResponse) {      
      this.invoiceError.emit('Invoice-service error: ' + error.error);
    }

  async create(invoice: InvoiceDto) {     
    const body: InvoiceUpdate =  {
      "id":0,
      "invoiceNumber": invoice.invoiceNumber,
      "payedDate": invoice.payedDate,
      "fullyPayed": invoice.fullyPayed,
      "description": invoice.description,
      "invoiceDate": invoice.invoiceDate,
      "customerId": invoice.customerId,
      "noHours": invoice.noHours,
      "hourlyRate": invoice.hourlyRate,
      "amount": invoice.amount,
      "vatRate": invoice.taxRate,
      "fiscalYear": invoice.fiscalYear,
      "creationUserId": invoice.creationUserId,
      "changeUserId": 0,
      "term": invoice.term,
      "sender": invoice.sender,
      "filename": invoice.filename
    };

    const source$ = this.http.post(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch(this.handleError);
    this.invoiceNew.emit(firstResult as InvoiceDto); 
  }

  public download(invoiceId: number)
  {
    var downloadUrl = this.baseUrl + '/download?invoiceId=' + invoiceId;
    return this.http.get(downloadUrl, {observe: 'response', responseType: 'blob'})
  }

  public upload(invoiceId: number, formData: FormData)
  {
     var uploadUrl = this.baseUrl + '/upload?invoiceId=' + invoiceId;
     return this.http.post(uploadUrl, formData);
  }

  generate(invoice: InvoiceDto) {    
    
    var payedDate = '';
    if (invoice.payedDate !== undefined && invoice.payedDate !== null)
        payedDate = invoice.payedDate.toLocaleDateString('en-US');

    let params = new HttpParams();
    params = params.append("id", invoice.id);
    params = params.append("invoiceNumber", invoice.invoiceNumber === null || invoice.invoiceNumber === undefined ? '' : invoice.invoiceNumber);
    params = params.append("payedDate", payedDate);
    params = params.append("fullyPayed", invoice.fullyPayed);
    params = params.append("description", invoice.description);
    params = params.append("invoiceDate", new Date(invoice.invoiceDate).toDateString());
    params = params.append("customerId", invoice.customerId);
    params = params.append("noHours", invoice.noHours === null ? 0 : invoice.noHours);
    
    params = params.append("hourlyRate", invoice.hourlyRate);    
    params = params.append("amount", invoice.amount);
    params = params.append("vatRate", invoice.taxRate);
    params = params.append("fiscalYear", invoice.fiscalYear),
    params = params.append("creationUserId", 0);
    params = params.append("changeUserId", 0);
    if (invoice.term !== undefined && invoice.term !== null)
         params = params.append("term", invoice.term);
    
    params = params.append("sender", invoice.sender);
    params = params.append("filename", invoice.filename === null || invoice.filename === undefined || invoice.filename === '' ? '' : invoice.filename);
  
    return this.http.get(this.baseUrl + '/generate', { observe: "response", params, responseType: 'blob' }).pipe(catchError( (error: HttpErrorResponse) => {
        if (error.status === 404)
          return throwError(() => new MyNotFoundError(error))       
        else
          return throwError(() => new AppError(error))
      })); 
    }

}
