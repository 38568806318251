<mat-drawer-container class="edit-task-container" autosize hasBackdrop="true" disableClose="true" position="end">
    <mat-drawer #drawer class="task-sidenav" mode="over">    
      <change-task></change-task>
    </mat-drawer>  
  <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel class="searchPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            search
          </mat-panel-title>
          <mat-panel-description>
            enter search criteria
            <mat-icon>search</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>  
  
        <table class="full-width" cellspacing="0">
        <td>
          <mat-form-field appearance="outline" >
            <mat-label>Name or Description</mat-label>
            <input matInput [(ngModel)]="searchData.name">
          </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline">
                <mat-label>Customer</mat-label>
                <mat-select #selectedCustomerId>
                  <mat-option *ngFor="let customer of customers" [value]="customer.id">
                    {{customer.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline">
                <mat-label>Project</mat-label>
                <mat-select #selectedProjectId>
                  <mat-option *ngFor="let project of projects" [value]="project.id">
                    {{project.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </td>
        <td>
          <number-results></number-results>
        </td>
      </table>
        <div class="searchButton">
          <button mat-raised-button (click)="clearSearchFields();" class="leftActionButton">clear</button>             
          <button mat-raised-button (click)="search()" class="actionButton">search</button>             
        </div>
      </mat-expansion-panel>        
    </mat-accordion>
  
  <div class="mat-elevation-z8 tableContent">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">     
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
              Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
              Name
              </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
          
          <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description">
                Description
              </th>
              <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
          
          <ng-container matColumnDef="customerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer name">
              Customer
              </th>
              <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
          </ng-container>
  
          <ng-container matColumnDef="projectName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date project name">
              Project
              </th>
              <td mat-cell *matCellDef="let element"> {{element.projectName}} </td>
          </ng-container>
         
  
        <ng-container matColumnDef="dateFinished">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date finished">
          Finished
          </th>
          <td mat-cell *matCellDef="let element"> {{formatDate(element.dateFinished)}} </td>
      </ng-container>
  
          
          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>              
                  <mat-icon (click)="addNew()" aria-label="Example icon-button with a heart icon" ngbTooltip="create task" class="actionIcon">add</mat-icon>              
                </th>      
              <td mat-cell *matCellDef="let element; let i=index;">
                  <mat-icon (click)="edit(element)" aria-label="Edit" class="actionIcon" ngbTooltip="edit task">edit</mat-icon>
                  <mat-icon (click)="delete(element['id'])" aria-label="Delete" ngbTooltip="delete task" class="actionIcon" color="warn">delete</mat-icon>
                </td>
            </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
          <mat-progress-spinner    
              mode="indeterminate">
          </mat-progress-spinner>
      </div>
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
  </div>
  </mat-drawer-container>
