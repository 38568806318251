<mat-card style="width: 400px;">
    <mat-card-content>

    <mat-form-field appearance="outline" class="nextRowItems">
      <mat-label>Name</mat-label>
      <input matInput 
      placeholder="Name"      
      [(ngModel)]="customer.name" 
      (change)="allRequiredFieldsFilled()">
      <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(customer.name)">Name required</mat-hint>
    </mat-form-field>
    
    <mat-form-field appearance="outline" class="nextRowItems">
      <mat-label>Address</mat-label>
      <input matInput placeholder="Address" [(ngModel)]="customer.address" (change)="allRequiredFieldsFilled()">
      <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(customer.address)">Address required</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" class="nextRowItems">
        <mat-label>House number</mat-label>
        <input matInput placeholder="number" [(ngModel)]="customer.houseNumber" (change)="allRequiredFieldsFilled()">
        <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(customer.houseNumber)">Number required</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="nextRowItems">
        <mat-label>Zip</mat-label>
        <input matInput placeholder="Zip" [(ngModel)]="customer.zip" (change)="allRequiredFieldsFilled()">
        <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(customer.zip)">Zip required</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="nextRowItems">
        <mat-label>City</mat-label>
        <input matInput placeholder="city" [(ngModel)]="customer.city" (change)="allRequiredFieldsFilled()">
        <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(customer.city)">City required</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="nextRowItems">
        <mat-label>Invoice to</mat-label>
        <input matInput placeholder="Invoice to" [(ngModel)]="customer.invoiceTo" (change)="allRequiredFieldsFilled()">
        <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(customer.invoiceTo)">Invoice-to (addressee) required</mat-hint>
      </mat-form-field>

       <div class="searchButton">
        <button mat-raised-button class="leftActionButton" (click)="closeDrawer()">cancel</button>             
        <button mat-raised-button class="actionButton" [disabled]="!allFieldsFilled" (click)="save()">save</button>             
      </div>
    </mat-card-content>
</mat-card>