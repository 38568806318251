import { Component, ViewChild } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { delay, filter } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Roles } from '../common/enums';


@UntilDestroy()
@Component({
  selector: 'bs-navbar',
  templateUrl: './bs-navbar.component.html',
  styleUrls: ['./bs-navbar.component.css']
})
export class BsNavbarComponent {

  isLoggedIn = this.authService.isLoggedIn();
  hasRoleBank = false;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  userName = this.authService.getFullName();
  title = 'LEGIT Office';

  constructor(public authService:AuthenticationService, private observer: BreakpointObserver, private router: Router){
    this.checkBankRole();
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });
  }

  checkBankRole () {
      this.hasRoleBank = this.authService.isUserInRole(Roles.bank);
  }
  
  checkLoggedInUser(){
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  logout(){
    this.isLoggedIn = false;
    this.authService.logout();
    this.router.navigate(['./login']);
  }

}
