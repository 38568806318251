import { Component } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { TransactionDto } from 'src/app/models/transaction';

@Component({
  selector: 'change-transaction',
  templateUrl: './change-transaction.component.html',
  styleUrls: ['./change-transaction.component.css']
})

export class ChangeTransactionComponent {

  bind(project: TransactionDto, drawer: MatDrawer) {

  }

}
