import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatDrawer } from '@angular/material/sidenav';
import { SimpleObject } from 'src/app/models/simple-object';
import { TaskDto } from 'src/app/models/task';
import { TaskService } from 'src/app/services/task.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectDto, ProjectSearch } from 'src/app/models/project';
import { MatOption } from '@angular/material/core';
import { ListsService } from 'src/app/services/lists.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'change-task',
  templateUrl: './change-task.component.html',
  styleUrls: ['./change-task.component.css']
})

export class ChangeTaskComponent implements OnInit, AfterViewInit{
  constructor(private taskService: TaskService, private projectService: ProjectService, private listsService: ListsService, public commonService: CommonService) {
    this.initLists();
  }

  matDrawer!: MatDrawer;
  customers!: SimpleObject[];
  projects!:SimpleObject[];
  isLoading = false;
  allFieldsFilled = false;
  allProjects!: ProjectDto[];
  oldTask = {};

  task = {
    "id": 0,
    "name": '',
    "projectId": 0,
    "projectName": '',
    "customerId": 0,
    "customerName": '',
    "dateFinished": undefined
  } as TaskDto;

  @ViewChild('selectedCustomerId') matSelectCustomer!: MatSelect;
  @ViewChild('selectedProjectId') matSelectProject!: MatSelect;

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  initLists() {

    //First get all the projects with all attributes (ProjectDto). 
    //Then in case a customer is selected, filter the project for that customer.
    //When a project is selected, find and show the customer.

    var searchData = {
       "results" : 100
    } as ProjectSearch;

    this.projectService.search(searchData)
    .subscribe(response => {
         this.allProjects =  response as ProjectDto[];
         this.projects = this.allProjects as SimpleObject[];
        }, error => {
            alert('searchProjects: An unexpected error occurred.');
            console.log(error);
      });

    var dataCustomers = this.listsService.getCustomerList();
  
    if (!this.commonService.stringIsNullOrEmpty(dataCustomers)) 
    {
      var orgCustomerList = JSON.parse(dataCustomers || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
      this.customers = orgCustomerList as SimpleObject[];
    }   
  }  

  new (drawer: MatDrawer) {

    this.matDrawer = drawer;
    this.projects = this.allProjects as SimpleObject[];

    this.task = {
      "id": 0,
      "name": '',
      "projectId": 0,
      "projectName": '',
      "customerId": 0,
      "customerName": '',
      //"noHours": 0,
      //"percentageCompleet": 0,
      "dateFinished": undefined
    } as TaskDto;

    this.matSelectCustomer.options.forEach((data: MatOption) => data.deselect());
    this.matSelectProject.options.forEach((data: MatOption) => data.deselect());
    
    this.allRequiredFieldsFilled();
    this.matDrawer.open();
  }

  bind (task: TaskDto, drawer: MatDrawer) {
       this.matDrawer = drawer;  
       this.task = task;
       this.oldTask = JSON.parse(JSON.stringify(task)); //Save current values in case update is cancelled.
       this.matSelectCustomer.value = this.task.customerId;
       this.matSelectProject.value = this.task.projectId;

       if (task.dateFinished !== null && task.dateFinished !== undefined)
          this.task.dateFinished = new Date(task.dateFinished);

       this.allRequiredFieldsFilled();

       this.matDrawer.open();
       this.isLoading = false;
  }

  save() {

    if (this.task.dateFinished !== null && this.task.dateFinished !== undefined)  {
      let UTCDate = Date.UTC(this.task.dateFinished.getFullYear(), this.task.dateFinished.getMonth(), this.task.dateFinished.getDate()) - this.task.dateFinished.getTimezoneOffset();
      this.task.dateFinished = new Date(UTCDate);
    }   

    var retValue: any;
    var newObject = this.task.id === 0;    
   
    retValue = newObject ? this.taskService.create(this.task) : this.taskService.update(this.task);
  }

  selectedCustomerChanged() {   
      if (this.matSelectCustomer.value !== undefined && this.matSelectCustomer.value !== null)
        this.projects = this.allProjects.filter(p => p.customerId === this.matSelectCustomer.value) as SimpleObject[];
      else 
        this.projects = this.allProjects as SimpleObject[];  
  }

  selectedProjectChanged() {
    if (this.matSelectProject.value !== undefined && this.matSelectProject.value !== null && this.matSelectProject.value !== '')
    {
      var idx = this.allProjects.findIndex(p => p.id === this.matSelectProject.value);
      var customerId = this.allProjects[idx].customerId
      this.matSelectCustomer.value = customerId;
      
      this.task.projectId = this.matSelectProject.value;
    }
    this.allRequiredFieldsFilled();
  }

  allRequiredFieldsFilled () {
    this.allFieldsFilled = this.task.name !== undefined && this.task.name !== null && this.task.name !== ''
    && this.matSelectProject.value !== undefined && this.matSelectProject.value !== null;
     return this.allFieldsFilled;
   }

  closeDrawer() {
    this.commonService.resetValues(this.task, this.oldTask); //For restoring values in the table-view
    this.matDrawer.close();
   }

   isProjectSelected () : boolean {
    return this.task.projectId > 0;
  }
}
