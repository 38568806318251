<mat-card style="width: 400px;">
    <mat-card-content>

<mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput placeholder="project name" [(ngModel)]="project.name" (change)="allRequiredFieldsFilled()">
      <mat-hint *ngIf="this.commonService.stringIsNullOrEmpty(project.name)">Name required</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Customer</mat-label>
      <mat-select #selectedCustomerId (valueChange)="customerSelectionChanged()">
        <mat-option *ngFor="let customer of customers" [value]="customer.id">
          {{customer.name}}
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="!isCustomerSelected()">Customer required</mat-hint>
    </mat-form-field>
        <div class="chkboxleftdiv">
          <mat-checkbox class="chkboxInleftdiv" [(ngModel)]="project.isFinished">Finished</mat-checkbox>
       </div>
        <div class="datepckrightdiv">
             <mat-form-field appearance="outline">
                <mat-label>Finish-date</mat-label>
                <input matInput [matDatepicker]="picker" [disabled]="!project.isFinished" [(ngModel)]="project.dateFinished">        
                <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="!project.isFinished"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
       </div>
       <div class="searchButton">
        <button mat-raised-button class="leftActionButton" (click)="closeDrawer()">cancel</button>             
        <button mat-raised-button class="actionButton" [disabled]="!allFieldsFilled" (click)="save()">save</button>             
      </div>
    </mat-card-content>
</mat-card>