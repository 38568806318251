
<mat-drawer-container class="edit-transaction-container" autosize hasBackdrop="true" disableClose="true" position="end">
    <mat-drawer #drawer class="transaction-sidenav" mode="over">    
      <change-transaction></change-transaction>
    </mat-drawer>  
  <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel class="searchPanel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            search
          </mat-panel-title>
          <mat-panel-description>
            enter search criteria
            <mat-icon>search</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>  
  
        <table class="full-width" cellspacing="0">
        <tr>
            <td class="firstSearchColumn">
                <mat-form-field appearance="outline" >
                    <mat-label>Description</mat-label>
                    <input matInput [(ngModel)]="searchData.description">
                </mat-form-field>
            </td>
            <td class="secondSearchColumn">
                <mat-form-field appearance="outline">
                    <mat-label>Date from</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="searchData.dateFrom" (dateChange)="addDateFromEvent($event)">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
            <td class="thirdSearchColumn">
                <mat-form-field appearance="outline" >
                    <mat-label>Reference</mat-label>
                    <input matInput [(ngModel)]="searchData.referenceName">
                </mat-form-field>
            </td>
            <td class="thirdSearchColumn">
                <number-results></number-results>
            </td>            
        </tr>
        <tr>
             <td>
                <mat-form-field appearance="outline" class="secondRowSearchItems">
                    <mat-label>Account</mat-label>
                    <mat-select #selectedAccountId>
                    <mat-option *ngFor="let account of accounts" [value]="account.id">
                        {{account.name}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
             </td>
             <td>
                <mat-form-field appearance="outline" class="secondRowSearchItems">
                    <mat-label>Date to</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="searchData.dateTo" (dateChange)="addDateToEvent($event)">        
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
             </td>
             <td>
                <mat-form-field appearance="outline" class="secondRowSearchItems">
                    <mat-label>Reference account</mat-label>
                    <input matInput [(ngModel)]="searchData.referenceNumber">
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" class="secondRowSearchItems">
                    <mat-label>Category</mat-label>
                    <mat-select #selectedCategoryId>
                    <mat-option *ngFor="let category of categories" [value]="category.id">
                        {{category.name}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
      </table>
        <div class="searchButton">
            <div [hidden]='!userHasRole()' style="float:left;">
                <mat-checkbox [(ngModel)]="isPrivate" (change)="changeConnection($event)">private</mat-checkbox>
            </div>
          <button mat-raised-button (click)="clearSearchFields();" class="leftActionButton">clear</button>             
          <button mat-raised-button (click)="search()" class="actionButton">search</button>             
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  
  <div class="mat-elevation-z8 tableContent">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
              Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
          <ng-container matColumnDef="accountNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by accountNumber">
                Account
              </th>
              <td mat-cell *matCellDef="let element"> {{element.accountNumber}} </td>
          </ng-container>
          
          <ng-container matColumnDef="valueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by valueDate">
                Value Date
              </th>
              <td mat-cell *matCellDef="let element"> {{formatDate(element.valueDate)}} </td>
          </ng-container>
          
          <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by amount">
                Amount
              </th>
              <td mat-cell *matCellDef="let element"> {{element.amount | currency:'EUR':'symbol-narrow'}} </td>
          </ng-container>
  
       <ng-container matColumnDef="saldo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date saldo">
                Saldo
              </th>
              <td mat-cell *matCellDef="let element"> {{element.saldo| currency:'EUR':'symbol-narrow'}} </td>
          </ng-container>
  
             <ng-container matColumnDef="referenceName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creation referenceName">
                Reference
              </th>
              <td mat-cell *matCellDef="let element"> {{element.referenceName}} </td>
          </ng-container>

          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creation reference">
                Reference Account
            </th>
            <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
        </ng-container>

      <ng-container matColumnDef="categoryName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creation categoryName">
                Category
            </th>
            <td mat-cell *matCellDef="let element"> {{element.categoryName}} </td>
        </ng-container>

     <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by creation description">
                Description
            </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
          
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
            <div class="container" style="text-align: center;">
                <input type="file" style="display: none" #fileInput accept=".csv" (change)="onChangeFileInput()" />
                  <mat-icon (click)="getFile()" ngbTooltip="load & validate transactions (.CSV)" class="actionIcon" [ngStyle]="{'color':'#392A1D'}">upload_file</mat-icon>
                  <!-- <mat-icon (click)="validateImport()" [class.toolbar-icon-disabled]="!validationEnabled" ngbTooltip="validate transactions" style="margin-left: 2px;" class="actionIcon" [ngStyle]="{'color':'#392A1D'}">checklist</mat-icon> -->
                  <mat-icon (click)="executeImport()" [class.toolbar-icon-disabled]="!importIsValid" ngbTooltip="import transactions" style="margin-left: 2px;" class="actionIcon" [ngStyle]="{'color':'#392A1D'}">import_export</mat-icon>
              </div> 
        </th>
  
        <td mat-cell *matCellDef="let element; let i=index;" style="text-align: center;">
            <mat-icon (click)="edit(element)" aria-label="Edit" class="actionIcon" ngbTooltip="edit transaction" *ngIf="element.id !== 0">edit</mat-icon>
            <mat-icon *ngIf="element.warning" [ngbTooltip]="element.validationMessage" [ngStyle]="{'color':'orange'}">warning_amber</mat-icon>
            <mat-icon *ngIf="element.error" [ngbTooltip]="element.validationMessage" [ngStyle]="{'color':'red'}">error_outline</mat-icon>
          </td>
      </ng-container> 

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'make-red': row.error, 'make-gold': row.warning }" ></tr> -->
      </table>
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
          <mat-progress-spinner    
              mode="indeterminate">
          </mat-progress-spinner>
      </div>
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
  </div>
  </mat-drawer-container>