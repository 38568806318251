import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatDrawer } from '@angular/material/sidenav';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TimesheetDto } from 'src/app/models/timesheet';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { User } from 'src/app/models/user';
import { SimpleObject } from 'src/app/models/simple-object';
import { LegitAutocompleteComponent } from 'src/app/common/controls/legit-autocomplete/legit-autocomplete.component';
import { TaskService } from 'src/app/services/task.service';
import { TaskDto } from 'src/app/models/task';
import { ListsService } from 'src/app/services/lists.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'change-timesheet',
  templateUrl: './change-timesheet.component.html',
  styleUrls: ['./change-timesheet.component.css']
})

export class ChangeTimesheetComponent implements OnInit, AfterViewInit  {
constructor(private authService: AuthenticationService, private timesheetService: TimesheetService, private taskService: TaskService, private listsService: ListsService, public commonService: CommonService) {
  this.initLists();
}

matDrawer!: MatDrawer;
timesheet =  { 
  "id":0,	    
  "date":new Date(),	
  "description":'',
  "userId": 0,
  "userName":'',    
  "taskId": 0,
  "noHours": 0,
  "noKilometers": 0,
  "invoiceId": 0,
  "creationUserId":0,
  "changeUserId":0,
}  as TimesheetDto;

users!: User[];
tasks!:SimpleObject[];
project!:string; //for displaying task => project
customer!:string; //for displaying task => project => customer 
oldTimesheet = {};
allFieldsFilled = false;

@ViewChild('selectedUserId') matSelectUser!: MatSelect;
@ViewChild('tasksAutoComplete') tasksAutoComplete!: LegitAutocompleteComponent;

ngOnInit(): void {
  this.authService.getUsers().subscribe(response => {
    this.users = response as User [];
  }); 
}

ngAfterViewInit(): void {
  this.tasksAutoComplete.itemSelectionChanged.subscribe(simpleObject => {  
  //Get customer-name and get project-name for current task.
  this.timesheet.taskId = simpleObject.id;
  this.taskService.getById(simpleObject.id.toString())
  .subscribe(response => {
    var task = response as TaskDto;
    this.customer = task.customerName;
    this.project = task.projectName;      
    this.allRequiredFieldsFilled();
  });
});
}

initLists() {
  var dataTasks = this.listsService.getTaskList();

  if (!this.commonService.stringIsNullOrEmpty(dataTasks))
  {
    var orgTaskList = JSON.parse(dataTasks || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
    this.tasks = orgTaskList as SimpleObject[];
  }
}

bind(timesheet: TimesheetDto, drawer: MatDrawer) {
  this.matDrawer = drawer;
  if (timesheet != null) {

    this.oldTimesheet = JSON.parse(JSON.stringify(timesheet)); //Save current values in case update is cancelled.

    this.timesheet = timesheet;
    this.matSelectUser.value = timesheet.userId;
    this.timesheet.date = new Date(timesheet.date);        
    this.tasksAutoComplete.setInitialValue(timesheet.taskName);

    this.allRequiredFieldsFilled();  
    this.matDrawer.open();
 }
}

new(drawer: MatDrawer) {  
  this.timesheet =  { 
    "id": 0,	    
    "date": new Date(),	
    "description": '',
    "userId": Number(this.authService.getUserId()),
    "userName": '',    
    "taskId": 0,
    "noHours": 0,
    "noKilometers": 0,
    "invoiceId": 0,
    "creationUserId": 0,
    "changeUserId": 0,
  }  as TimesheetDto;
  
  this.matSelectUser.value = this.timesheet.userId;
  this.allRequiredFieldsFilled();

  this.matDrawer = drawer;
  drawer.open();
}

save() {

  //Dirty trick to fix the date, in order to get rid of the TimezoneOffset.
  let UTCDate = Date.UTC(this.timesheet.date.getFullYear(), this.timesheet.date.getMonth(), this.timesheet.date.getDate()) - this.timesheet.date.getTimezoneOffset();
  this.timesheet.date = new Date(UTCDate);    

  var retValue = this.timesheet.id === 0 ? this.timesheetService.create(this.timesheet) : this.timesheetService.update(this.timesheet);
  
}

allRequiredFieldsFilled () {
  this.allFieldsFilled =
  this.timesheet.description !== null && this.timesheet.description !== undefined && this.timesheet.description !== ''
  && this.timesheet.userId !== undefined && this.timesheet.userId !== null && this.timesheet.userId !== 0
  && this.timesheet.noHours !== undefined && this.timesheet.noHours !== null && this.timesheet.noHours !== 0
  && this.timesheet.date !== undefined && this.timesheet.date !== null
  && this.timesheet.taskId !== undefined && this.timesheet.taskId !== null && this.timesheet.taskId !== 0;

  return this.allFieldsFilled;
}

setDefaultTask(taskName:string)
{
  this.tasksAutoComplete.setInitialValue(taskName);
}

userSelectionChanged() {
  this.timesheet.userId = this.matSelectUser.value;
  this.allRequiredFieldsFilled();
}

isUserSelected() : boolean {
  return this.timesheet.userId > 0;
}

closeDrawer() {
  this.commonService.resetValues(this.timesheet, this.oldTimesheet); //For restoring values in the table-view
  this.matDrawer.close();
 }
}
