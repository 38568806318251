import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ImportsComponent } from './imports/imports.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { AdminTasksComponent } from './admin/admin-tasks/admin-tasks.component';
import { AdminProjectsComponent } from './admin/admin-projects/admin-projects.component';
import { AdminCustomersComponent } from './admin/admin-customers/admin-customers.component';
import { BankTransactionComponent } from './bank/bank-transaction/bank-transaction.component';
import { BankImportComponent } from './bank/bank-import/bank-import.component';

const routes: Routes = [{path:'',redirectTo:'login',pathMatch:'full'},  
{path: 'home', component: HomeComponent},      
{path: 'timesheet', component: TimesheetComponent},
{path: 'invoices', component: InvoicesComponent},
{path: 'imports', component: ImportsComponent},
{path: 'login', component: LoginComponent},
{path: 'admin/tasks', component: AdminTasksComponent},
{path: 'admin/projects', component: AdminProjectsComponent},
{path: 'admin/customers', component: AdminCustomersComponent},
{path: 'bank/imports', component: BankImportComponent},
{path: 'bank/transactions', component: BankTransactionComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
