import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';
import { MyNotFoundError } from '../common/not-found-error';
import { environment } from 'src/environments/environment';
import { ProjectUpdate, ProjectDto, ProjectSearch } from '../models/project';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {
  private baseUrl = environment.baseUrl + '/project';

  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  projectError: EventEmitter<string> = new EventEmitter<string>();
  projectChanged: EventEmitter<ProjectDto> = new EventEmitter<ProjectDto>();
  projectNew: EventEmitter<ProjectDto> = new EventEmitter<ProjectDto>();

  constructor(private http: HttpClient) {  }

//var projectId = this.searchData.id == null ? '' : this.searchData.id.toString();
      //var customerId = this.searchData.customerId == null ? '' : this.searchData.customerId.toString();
      //var name = this.searchData.name == null ? '' : this.searchData.name

  search(searchData: ProjectSearch) {    
    var projectSearchUrl = 
    '?id=' + (searchData.id === undefined ? '' : searchData.id)
    + '&name=' + (searchData.name === undefined ? '' : searchData.name)
    + '&customerId=' + (searchData.customerId === undefined ? '' : searchData.customerId)
    + '&results=' + searchData.results;
    return this.http.get(this.baseUrl + projectSearchUrl) .pipe(catchError( (error: HttpErrorResponse) => {
      if (error.status === 404)
        return throwError(() => new MyNotFoundError(error))       
      else
        return throwError(() => new AppError(error))
    }));
  }

  async update(project: ProjectDto) { 
    const body: ProjectUpdate =  {
      "id": project.id, 
      "customerId": project.customerId,
      "name": project.name, 
      "isFinished": project.isFinished,
      "dateFinished": project.dateFinished, 
      "creationUserId": 0,
      "changeUserId": project.changeUserId
    }
    const source$ = this.http.put(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));    
    this.projectChanged.emit(firstResult as ProjectDto);   
    }
    
    handleError(error: HttpErrorResponse) {
      this.projectError.emit('Project-service error: ' + error.error);
    }

  async create(project: ProjectDto) { 
    const body: ProjectUpdate =  {
      "id": 0, 
      "customerId": project.customerId,
      "name": project.name, 
      "isFinished": project.isFinished,
      "dateFinished": project.dateFinished, 
      "creationUserId": project.creationUserId, 
      "changeUserId": 0
    };
    const source$ = this.http.post(this.baseUrl, body, this.HttpOptions);
    const firstResult = await firstValueFrom(source$).catch(this.handleError);
    this.projectNew.emit(firstResult as ProjectDto); 
    }

  async getList() {     
    const source$ = this.http.get(this.baseUrl + '/getprojectlist');
    const firstResult = await firstValueFrom(source$).catch((error: HttpErrorResponse) => this.handleError(error));        
    return firstResult;
  }
}
