import { LiveAnnouncer} from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { CustomerDto, CustomerSearch } from 'src/app/models/customer';
import { DialogService } from 'src/app/services/dialog.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MatAccordion } from '@angular/material/expansion';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { MatDrawer } from '@angular/material/sidenav';
import { ChangeCustomerComponent } from './change-customer/change-customer.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NumberResultsComponent } from 'src/app/common/controls/number-results/number-results.component';
import { SimpleObject } from 'src/app/models/simple-object';
import { ListsService } from 'src/app/services/lists.service';


const custArr: CustomerDto[] = [];

@Component({
  selector: 'app-admin-customers',
  templateUrl: './admin-customers.component.html',
  styleUrls: ['./admin-customers.component.css']
})
export class AdminCustomersComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ['id','name','city','address','houseNumber', 'zip', 'invoiceTo', 'actions'];
  dataSource = new MatTableDataSource(custArr);
  isLoading = false;
  searchData = {} as CustomerSearch;
  customers!: SimpleObject[];


  constructor(private _liveAnnouncer: LiveAnnouncer, private listsService: ListsService, private service: CustomerService, private dialogServie: DialogService, private _snackBar: MatSnackBar) { 

  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;  
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('drawerContainer') drawerContainer!: MatDrawerContainer;
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild(ChangeCustomerComponent) changeCustomerComponent!: ChangeCustomerComponent;
  @ViewChild(NumberResultsComponent) numberResultsComponent!: NumberResultsComponent;

  ngAfterViewInit() {
    this.accordion.openAll();
  }

  ngOnInit(): void {   
    this.service.customerChanged.subscribe(customer => {
      const index = this.dataSource.data.findIndex((item) => item.id === customer.id);
      if (index > -1) {
        this.dataSource.data[index] = customer;
        this.dataSource.paginator = this.paginator;
    }
      this.drawer.close();
      this._snackBar.open("Customer saved." , "close", { duration: 5000 });
      this.listsService.updateCustomerList(customer.id, customer.name);
    });
    
    this.service.customerNew.subscribe(customer => {
      this.dataSource.data.push(customer);
      this.dataSource.paginator = this.paginator;      
      this.drawer.close();
      this._snackBar.open("Customer created." , "close", { duration: 5000 });
      this.listsService.updateCustomerList(customer.id, customer.name);
    });
  
    this.service.customerError.subscribe(message => {    
      this._snackBar.open(message, "close", { verticalPosition: 'top'});
    });   
    
    this.service.customerDeleted.subscribe(id => {    
      var deletedElementIdx = this.dataSource.data.findIndex(t => t.id === id);
      var data = this.dataSource.data;         
      this.dataSource.data = data.filter((i, index) => index !== deletedElementIdx);
      this.dataSource.paginator = this.paginator;    
      this.dataSource.sort = this.sort;

      this._snackBar.open("Customer deleted", "close", { duration: 5000 });
      this.listsService.deleteFromCustomerList(id);
    });  
  }

  clearSearchFields() {
    this.searchData = {} as CustomerSearch;
    this.dataSource = new MatTableDataSource([] as CustomerDto[]);
  }

  search()
  {
    this.isLoading = true;
      var id = '0';
      var name = this.searchData.name == null ? '' : this.searchData.name;
      var address = this.searchData.address == null ? '' : this.searchData.address;
      var numberResults = this.numberResultsComponent.getSelectedValue();

      this.service.search(id, name, address, numberResults)
    .subscribe(response => {        
        this.dataSource = new MatTableDataSource(response as CustomerDto[]);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.accordion.closeAll();
        this.isLoading = false;
        }, error => {
            alert('searchCustomers: An unexpected error occurred.' + error);
            this.isLoading = false;
            console.log(error);
        });
  }

  addNew() {
    this.changeCustomerComponent.new(this.drawer);
  }

  delete(id: any) {
    this.dialogServie.confirmDialog({
      title: 'Confirm please',
      message: 'Are you sure you want to delete customer ' + id + '?',
      confirmText: 'Yes',
      cancelText: 'No'
    }).subscribe(data => 
      { 
        if (data == true)
        {
          console.log('DELETE');
          this.service.delete(id);
        }
        else
          console.log('do not delete');
      });    
  }

  edit(customer: CustomerDto) {
      this.changeCustomerComponent.bind(customer, this.drawer);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
