import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { ProjectDto } from 'src/app/models/project';
import { MatSelect } from '@angular/material/select';
import { MatDrawer } from '@angular/material/sidenav';
import { ProjectService } from 'src/app/services/project.service';
import { SimpleObject } from 'src/app/models/simple-object';
import { ListsService } from 'src/app/services/lists.service';
import { CommonService } from 'src/app/services/common.service';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'change-project',
  templateUrl: './change-project.component.html',
  styleUrls: ['./change-project.component.css']
})
export class ChangeProjectComponent implements OnInit, AfterViewInit {
  constructor(private projectService: ProjectService, private listsService: ListsService, public commonService: CommonService) { 
    this.initLists();
}

  customers!: SimpleObject[];
  project = {
    "id": 0,
    "customerName": '',
    "customerId": 0,
    "name": '',
    "isFinished": false,
    "dateFinished": undefined,
    "creationDate": '',    
    "creationUserId": 0,
    "creationUserName": '',
    "changeDate": '',
    "changeUserId": 0,
    "changeUserName": '',    
    "editDateFinished": new Date()
  } as ProjectDto;

  matDrawer!: MatDrawer;
  oldProject = {};
  allFieldsFilled = false;

  @ViewChild('selectedCustomerId') matSelectCustomer!: MatSelect;

  ngOnInit(): void {  }

  ngAfterViewInit(): void { }

  initLists() {
    var dataCustomers = this.listsService.getCustomerList();
  
    if (!this.commonService.stringIsNullOrEmpty(dataCustomers))
    {
      var orgCustomerList = JSON.parse(dataCustomers || '{}'); // the part || '{}' is a trick. Eventhough everything is tested in the function 'commonService.stringIsNullOrEmpty' this part needs to be added, otherwise the compiler shows an error.
      this.customers = orgCustomerList as SimpleObject[];
    }
  }

  bind(project: ProjectDto, drawer: MatDrawer) {
  this.matDrawer = drawer;
    if (project != null) {
      this.project = project;
      this.oldProject = JSON.parse(JSON.stringify(project)); //Save current values in case update is cancelled.
      if (this.project.dateFinished != null)
        this.project.dateFinished = new Date(this.project.dateFinished); //json received from an API does not deliver correct TS dates.

      this.matSelectCustomer.value = project.customerId;
      
      this.allRequiredFieldsFilled();
    }

    this.matDrawer.open();
 }

 new (drawer: MatDrawer) {  
  this.matDrawer = drawer;
  this.project = {
    "id": 0,
    "customerName": '',
    "customerId": 0,
    "name": '',
    "isFinished": false,
    "dateFinished": undefined,
    "creationDate": '',    
    "creationUserId": 0,
    "creationUserName": '',
    "changeDate": '',
    "changeUserId": 0,
    "changeUserName": '',    
    "editDateFinished": new Date()
  } as ProjectDto;

  this.matSelectCustomer.options.forEach((data: MatOption) => data.deselect());
  this.allRequiredFieldsFilled();  
  this.matDrawer.open();
 }

 save() {

  //Sending a date back that is changed on the client-side needs an adjustment.
  if (this.project.dateFinished !== null && this.project.dateFinished !== undefined)  {
    let UTCDate = Date.UTC(this.project.dateFinished.getFullYear(), this.project.dateFinished.getMonth(), this.project.dateFinished.getDate()) - this.project.dateFinished.getTimezoneOffset();
    this.project.dateFinished = new Date(UTCDate);
  }

  //Call to project-service to save project.
  var retValue: any;
  var newObject = this.project.id === 0;    
  
  retValue = newObject ? this.projectService.create(this.project) : this.projectService.update(this.project);
  
}

 allRequiredFieldsFilled () {
  
   this.allFieldsFilled =
    this.project.name !== null && this.project.name !== undefined && this.project.name !== ''
    && this.matSelectCustomer.value !== undefined && this.matSelectCustomer.value !== null && this.matSelectCustomer.value !== '';
 
   return this.allFieldsFilled;    
 }

 closeDrawer() {
  this.commonService.resetValues(this.project, this.oldProject); //For restoring values in the table-view
  this.matDrawer.close();
 }

 customerSelectionChanged() {
   this.project.customerId = this.matSelectCustomer.value;
   this.allRequiredFieldsFilled();
 }  

 isCustomerSelected () : boolean {
  return this.project.customerId > 0;
}

}
