<div class="header">
    <h2 mat-dialog-title class="dialogTitle">{{data?.title}}</h2>
</div>

<div mat-dialog-content>
    {{data?.message}}
</div>

<div mat-dialog-actions [align]="'end'">
    <button mat-raised-button [mat-dialog-close]="false">{{data?.cancelText}}</button>
    <button mat-raised-button [mat-dialog-close]="true" class="actionButton">{{data?.confirmText}}</button>
</div>